.react-google-photo-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: black;
    overflow: hidden;
    z-index: 1;
  }
  
  .react-google-photo-column {
    position: absolute;
    width: 30%;
    top: 0;
    bottom: 0;
    cursor: pointer;
    transition: opacity 200ms ease;
    opacity: 0;
  }
  .react-google-photo-column:hover {
    opacity: 1;
  }
  .react-google-photo-left-column {
    left: 0;
  }
  .react-google-photo-right-column {
    right: 0;
  }
  
  .react-google-photo-arrow-button-return {
    height: 48px;
    width: 48px;
    position: absolute;
    top: 8;
    left: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 200ms ease;
    opacity: 1;
  }
  
  .react-google-photo-arrow-button {
    background-color: rgba(66, 66, 66, 0.54);
    border-radius: 28px;
    position: absolute;
    top: 50%;
    margin-top: -28px;
    height: 56px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 200ms ease;
    opacity: 1;
  }
  .react-google-photo-arrow-button-hide {
    opacity: 0;
  }
  .react-google-photo-arrow-button-left {
    left: 28px;
  }
  .react-google-photo-arrow-button-right {
    right: 28px;
  }
  
  .react-google-photo-overlay-image {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 500ms ease, transform 500ms ease;
  }
  .react-google-photo-overlay-image-open {
    visibility: initial;
    opacity: 1;
    transform: translateX(0px);
  }
  
  @keyframes react-google-photo-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes react-google-photo-fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }